import {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import useAuth from "../auth/useAuth";
import Layout from "../layout/Layout";
import PageLoading from "../components/loading/PageLoading";
import {img} from "../utils/image";
import WaitLoading from "../components/loading/WaitLoading";
import {popErrors} from "../utils/message";
import {getCurrentRootRelativePath, url} from "../utils/urls";
import {fetchPost} from "../utils/fetch";
import "../../style/login/_login.scss";
import ULMenuList from "../components/menu/ULMenuList";
import {convertToKeyMapElements} from "../utils/objects";
import CustomInput from "../components/form/CustomInput";
import {isNotEmpty, nullVal} from "../utils/strings";
import {getElementByDevice, useDevicePc} from "../utils/device";
import parse from "html-react-parser";

/**
 * ログインページ
 * @returns {Element}
 * @constructor
 */
const Login = () => {
  const [ loading, setLoading ] = useState(true);
  const [ waiting, setWaiting ] = useState(false);

  const [ base, setBase ] = useState({});
  const [ baseMask, setBaseMask ] = useState({});
  const [ loginForm, setLoginForm ] = useState({});
  const [ logo, setLogo ] = useState({});
  const [ title, setTitle ] = useState({});
  const [ button, setButton ] = useState({});
  const [ footer, setFooter ] = useState({});

  // umaca
  //const [receiptNumber, setReceiptNumber] = useState("ts0000238");
  //const [acceptId, setAcceptId] = useState("d6mej5");

  // 和牛神楽
  // const [receiptNumber, setReceiptNumber] = useState("000000238");
  // const [acceptId, setAcceptId] = useState("178m80");

   const [receiptNumber, setReceiptNumber] = useState("");
   const [acceptId, setAcceptId] = useState("");

  const [ tel, setTel ] = useState({});
  const [ hours, setHours ] = useState({});

  const navigate = useNavigate();
  const { authed, login, logout, setAuthed, setLottery } = useAuth();

  const [ menus, setMenus ] = useState([]);

  const pc = useDevicePc();

  /**
   * ログイン処理
   */
  const handleLogin = () => {
    // ローディング
    setWaiting(true);
    // ログイン処理
    login({
      recept_number: receiptNumber,
      login_id: acceptId
    }).then((data) => {
      // ログイン成功したら承認フラグをonにしてトップへ遷移する
      setAuthed(true);
      // くじギフトあり
      setLottery(data["lottery"]);
      navigate("/");
    }).catch((error) => {
      console.log(error);
      popErrors(error);
    }).finally(() => {
      setWaiting(false);
    });
  };

  /**
   * ログイン画面表示
   */
  let ignore = false;
  useEffect(() => {
    if(!ignore) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ignore = true;
      // すでに初期化されていたら処理を抜ける
      if (!loading) {
        return;
      }
      if (authed) {
        logout();
      }
      // ----------------------------
      // ログインページ情報取得する
      // ----------------------------
      fetchPost({
        action: url("/api/page/get/login"),
        data: {path: getCurrentRootRelativePath()}
      })
        .then((result) => {
          // 電話
          setTel(result.tel ?? "");
          // 営業時間
          setHours(result.hours ?? "");
          // メニュー
          setMenus(result.menus);
          let mapElements = convertToKeyMapElements(result["keyElements"]);
          if(mapElements) {
            // ページデザイン情報
            setBase(getElementByDevice(pc, mapElements["page"]));
            // ページデザインマスク情報
            setBaseMask(mapElements["page_mask"] ?? {});
            // ログイフォームのカスタマイズ
            setLoginForm(getElementByDevice(pc, mapElements["login_form"] ?? {}));
            // タイトルロゴのカスタマイズ
            setLogo(getElementByDevice(pc, mapElements["title_logo"]));
            // タイトルのカスタマイズ
            setTitle(mapElements["title"] ?? {});
            // ボタンのカスタマイズ
            setButton(mapElements["login_button"] ?? {});
            // フッターのカスタマイズ
            setFooter(mapElements["footer"] ?? {});
          }

          if(isNotEmpty(result["loginMessage"])) {
            popErrors(result["loginMessage"]);
          }
        })
        .catch((reason) => {
          popErrors(reason);
        })
        .finally(() => {
          // ページローディング終了
          setLoading(false);
          ignore = false;
        });
    }
  }, [loading]);


  return (
    (loading ?
        <PageLoading />
    :
    <Layout isHeader={false}
            isFooter={false}
            isCommonInfo={false}
            pageLoading={loading}
            meta={{title: "ログイン", description: "ログインする画面"}}>
      {waiting &&
        <WaitLoading top="40%" />
      }
      <div id="main" className="container-fluid login"
           style={base.style ?? {}}
      >
        {isNotEmpty(base["css"]) && <style>{parse(base["css"])}</style>}

        <div className="row">
          {/*背景画像*/}
          <div className={"col-12 " + nullVal(base.attr.className) }
               style={{
                 background: "url('" + img(base.attr.href) + "') no-repeat center center / cover",
          }} >
            {/*背景マスク*/}
            {Object.keys(baseMask).length !== 0 &&
            <div className={isNotEmpty(baseMask.className) ? baseMask.className : "z-0"} style={baseMask.style}></div>
            }
            {/*ロゴ タイトル*/}
            <div className={"z-1 " + logo.attr?.className} style={logo.style}>
              {isNotEmpty(logo.attr.src) &&
              <div className={"mx-auto text-center"} >
                <img className="img-fluid" src={img(logo.attr.src)} alt=""/>
              </div>
              }
              {isNotEmpty(title["innerText"]) &&
              <div className="mt-2 mb-3 text-center" style={title["style"]}>{parse(title["innerText"])}</div>
              }
            </div>
            {/*ログインフォーム*/}
            <div className={"z-1 " + loginForm.attr.className} style={loginForm.style}>
              {/*メッセージ*/}
              <div className="mt-1 mt-md-2 mb-2 text-center form-message">{parse(loginForm.innerText)}</div>
              <div className="form-input-area">
                {/*受付番号*/}
                <div className="row mb-3">
                  <div className="col-4 login-label">受付番号</div>
                  <div className="col-8">
                    <CustomInput id="recept-number" type="text" className="form-control form-control-sm"
                                 value={receiptNumber}
                                 setValue={setReceiptNumber}/>
                  </div>
                </div>
                {/*ログインID*/}
                <div className="row mb-3">
                  <div className="col-4 login-label">ログインID</div>
                  <div className="col-8">
                    <CustomInput id="login-id" type="text" className="form-control form-control-sm"
                                 value={acceptId}
                                 setValue={setAcceptId}/>
                  </div>
                </div>
                {/*ログインボタン*/}
                <button onClick={handleLogin} className={button.attr.className} style={button.style}
                >ログイン
                </button>
              </div>
            </div>
            {/*フッタ*/}
            <div className={footer.attr.className} style={footer.style}>
              {/*店舗メニュー*/}
              <ULMenuList menus={menus} ulClassName="linkList" title="" fontColor={footer.style?.color}/>
              {/*営業時間*/}
              <p className="text02">
                <span className="roboto">TEL:<a href={"tel:" + tel} style={{color: footer.style?.color}}>{tel}</a></span>（{hours}）
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    )
  )
}

export default Login